
  import { Component, Watch, Prop, Ref, Vue } from "vue-property-decorator";
  import { FormBase, FormFile, FormInput, FormSelect, FormError, FormSubmit } from "@/components/forms";
  import { hexToRgb } from "@/utils/color-helpers";
  import { OfficePatchPayload, Office } from "@/store/modules/office.store";
  const cloneDeep = require("lodash/cloneDeep");

  interface SelectOption {
    label: string;
    value: string;
  }

  @Component({
    components: {
      FormBase,
      FormInput,
      FormError,
      FormSelect,
      FormFile,
      FormSubmit,
      Spinner: () => import("@/components/general/spinner.vue"),
    },
  })
  export default class PartOfficeForm extends Vue {
    @Prop() initialValues?: Office;
    @Prop() submitting?: boolean;

    @Ref() form!: FormClass;

    payload: OfficePatchPayload | null = null;

    logo: Office["theme"]["logo"] | null = null;
    signature: Office["representative"]["signature"] | null = null;

    colors = {
      primary: {
        r: 230,
        g: 234,
        b: 238,
      },
      accent: {
        r: 255,
        g: 255,
        b: 255,
      },
      primary_light: {
        r: 230,
        g: 234,
        b: 238,
      },
      accent_light: {
        r: 255,
        g: 255,
        b: 255,
      },
    };

    legalCountry = {
      value: "BE",
      label: "België",
    };

    country = {
      value: "BE",
      label: "België",
    };

    officeType: SelectOption | null = null;

    countries: SelectOption[] = [
      {
        value: "BE",
        label: "België",
      },
    ];

    officeTypes: SelectOption[] = [
      { label: "Vastgoedkantoor", value: "Vastgoedkantoor" },
      { label: "Landmeetkantoor", value: "Landmeetkantoor" },
    ];

    uploadingFile: string | false = false;

    mounted() {
      if (this.initialValues) {
        this.payload = {
          id: this.initialValues.id,
          name: this.initialValues.name,
          theme: {
            color_primary: this.initialValues.theme.colors.primary ? cloneDeep(this.initialValues.theme.colors.primary) : "#E6EAEE",
            color_accent: this.initialValues.theme.colors.accent ? cloneDeep(this.initialValues.theme.colors.accent) : "#FFFFFF",
            color_primary_light: this.initialValues.theme.colors.primary_light ? cloneDeep(this.initialValues.theme.colors.primary_light) : "#FFFFFF",
            color_accent_light: this.initialValues.theme.colors.accent_light ? cloneDeep(this.initialValues.theme.colors.accent_light) : "#FFFFFF",
          },
        };

        if (this.initialValues.legal_name) {
          this.payload.legal_name = this.initialValues.legal_name;
        }

        if (this.initialValues.short_name) {
          this.payload.short_name = this.initialValues.short_name;
        }

        if (this.initialValues.vat_number) {
          this.payload.vat_number = this.initialValues.vat_number;
        }

        if (this.initialValues.phone) {
          this.payload.phone = this.initialValues.phone;
        }

        if (this.initialValues.email) {
          this.payload.email = this.initialValues.email;
        }

        if (this.initialValues.website_url) {
          this.payload.website_url = this.initialValues.website_url;
        }

        if (this.initialValues.privacy_policy_url) {
          this.payload.privacy_policy_url = this.initialValues.privacy_policy_url;
        }

        if (this.initialValues.escrow) {
          this.payload.escrow = this.initialValues.escrow;
        }

        if (this.initialValues.type) {
          this.payload.type = this.initialValues.type;
          this.officeType = this.officeTypes.find((type) => type.value === this.initialValues!.type) ?? null;
        }

        if (this.initialValues.competent_court) {
          this.payload.competent_court = this.initialValues.competent_court;
        }

        if (this.initialValues.competent_court_department) {
          this.payload.competent_court_department = this.initialValues.competent_court_department;
        }

        if (this.initialValues.partners) {
          this.payload.partners = {};

          if (this.initialValues.partners.vastgoedexperts_billing_email) {
            this.payload.partners.vastgoedexperts_billing_email = this.initialValues.partners.vastgoedexperts_billing_email;
          }
          if (this.initialValues.partners.vastgoedexperts_information_email) {
            this.payload.partners.vastgoedexperts_information_email = this.initialValues.partners.vastgoedexperts_information_email;
          }
        }

        if (this.initialValues.representative) {
          this.payload.representative = {};

          if (this.initialValues.representative.biv_number) {
            this.payload.representative.biv_number = this.initialValues.representative.biv_number;
          }

          if (this.initialValues.representative.function) {
            this.payload.representative.function = this.initialValues.representative.function;
          }

          if (this.initialValues.representative.function_short) {
            this.payload.representative.function_short = this.initialValues.representative.function_short;
          }

          if (this.initialValues.representative.first_name) {
            this.payload.representative.first_name = this.initialValues.representative.first_name;
          }

          if (this.initialValues.representative.last_name) {
            this.payload.representative.last_name = this.initialValues.representative.last_name;
          }

          if (this.initialValues.representative.signature) {
            this.signature = this.initialValues.representative.signature;
          }
        }

        if (this.initialValues.address) {
          if (Array.isArray(this.initialValues.address) && this.initialValues.address.length === 0) {
            this.payload.address = {};
          } else {
            // @ts-ignore
            this.payload.address = this.initialValues.address;
          }
        }

        if (this.initialValues.legal_address) {
          if (Array.isArray(this.initialValues.legal_address) && this.initialValues.legal_address.length === 0) {
            this.payload.legal_address = {};
          } else {
            // @ts-ignore
            this.payload.legal_address = this.initialValues.legal_address;
          }
        } else {
          this.payload.legal_address = {};
        }

        if (this.initialValues.theme.colors) {
          if (this.initialValues.theme.colors.primary) {
            const primaryRgb = hexToRgb(this.initialValues.theme.colors.primary);

            if (primaryRgb.length === 3) {
              this.colors.primary.r = primaryRgb[0];
              this.colors.primary.g = primaryRgb[1];
              this.colors.primary.b = primaryRgb[2];
            }
          }
          if (this.initialValues.theme.colors.accent) {
            const accentRgb = hexToRgb(this.initialValues.theme.colors.accent);

            if (accentRgb.length === 3) {
              this.colors.accent.r = accentRgb[0];
              this.colors.accent.g = accentRgb[1];
              this.colors.accent.b = accentRgb[2];
            }
          }
          if (this.initialValues.theme.colors.accent_light) {
            const accentLightRgb = hexToRgb(this.initialValues.theme.colors.accent_light);

            if (accentLightRgb.length === 3) {
              this.colors.accent_light.r = accentLightRgb[0];
              this.colors.accent_light.g = accentLightRgb[1];
              this.colors.accent_light.b = accentLightRgb[2];
            }
          }
          if (this.initialValues.theme.colors.primary_light) {
            const primaryLightRgb = hexToRgb(this.initialValues.theme.colors.primary_light);

            if (primaryLightRgb.length === 3) {
              this.colors.primary_light.r = primaryLightRgb[0];
              this.colors.primary_light.g = primaryLightRgb[1];
              this.colors.primary_light.b = primaryLightRgb[2];
            }
          }
        }

        this.logo = this.initialValues.theme.logo;
      }
    }

    handleFileUploaded(media: Media | null, name: "full" | "logo" | "inverse" | "email" | "signature" | "small" | "small_inverse") {
      this.uploadingFile = name;

      if (media && name === "signature") {
        this.signature = media;
      } else if (media && this.logo) {
        this.logo[name as "full" | "logo" | "inverse" | "email" | "small" | "small_inverse"] = media;
      }
    }

    @Watch("media", { deep: true })
    handleMediaChanged() {
      this.uploadingFile = false;
    }

    @Watch("logo", { deep: true })
    handleLogoChanged() {
      if (this.payload && this.logo) {
        if (this.logo.logo) {
          this.payload.logo = [
            {
              uuid: this.logo.logo.uuid,
              name: this.logo.logo.name,
            },
          ];
        }
        if (this.logo.full) {
          this.payload.logo_full = [
            {
              uuid: this.logo.full.uuid,
              name: this.logo.full.name,
            },
          ];
        }

        if (this.logo.inverse) {
          this.payload.logo_inverse = [
            {
              uuid: this.logo.inverse.uuid,
              name: this.logo.inverse.name,
            },
          ];
        }

        if (this.logo.email) {
          this.payload.logo_email = [
            {
              uuid: this.logo.email.uuid,
              name: this.logo.email.name,
            },
          ];
        }

        if (this.logo.small) {
          this.payload.logo_small = [
            {
              uuid: this.logo.small.uuid,
              name: this.logo.small.name,
            },
          ];
        }

        if (this.logo.small_inverse) {
          this.payload.logo_small_inverse = [
            {
              uuid: this.logo.small_inverse.uuid,
              name: this.logo.small_inverse.name,
            },
          ];
        }
      }
    }

    @Watch("signature", { deep: true })
    handleSignatureChanged() {
      if (this.payload && this.signature) {
        if (this.signature) {
          this.payload.signature = [
            {
              uuid: this.signature.uuid,
              name: this.signature.name,
            },
          ];
        }
      }
    }

    updateLocalColors() {
      if (this.payload && this.payload.theme && (this.payload.theme.color_primary || this.payload.theme.color_accent)) {
        if (this.payload.theme.color_primary) {
          try {
            const primaryRgb = hexToRgb(this.payload.theme.color_primary);

            if (primaryRgb.length === 3) {
              this.colors.primary.r = primaryRgb[0];
              this.colors.primary.g = primaryRgb[1];
              this.colors.primary.b = primaryRgb[2];
            }
          } catch (error) {
            console.log(error);
          }
        }
        if (this.payload.theme.color_accent) {
          try {
            const accentRgb = hexToRgb(this.payload.theme.color_accent);

            if (accentRgb.length === 3) {
              this.colors.accent.r = accentRgb[0];
              this.colors.accent.g = accentRgb[1];
              this.colors.accent.b = accentRgb[2];
            }
          } catch (error) {
            console.log(error);
          }
        }
        if (this.payload.theme.color_primary_light) {
          try {
            const primaryLightRgb = hexToRgb(this.payload.theme.color_primary_light);

            if (primaryLightRgb.length === 3) {
              this.colors.accent.r = primaryLightRgb[0];
              this.colors.accent.g = primaryLightRgb[1];
              this.colors.accent.b = primaryLightRgb[2];
            }
          } catch (error) {
            console.log(error);
          }
        }
        if (this.payload.theme.color_accent) {
          try {
            const accentLightRgb = hexToRgb(this.payload.theme.color_accent);

            if (accentLightRgb.length === 3) {
              this.colors.accent.r = accentLightRgb[0];
              this.colors.accent.g = accentLightRgb[1];
              this.colors.accent.b = accentLightRgb[2];
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    }
  }
